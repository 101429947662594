<template>
  <main class="view">
    <div class="col col-12 tabs-wrapper">
      <ul class="tab-controller" :class="[!showTabs ? 'hidden' : '']" style="--submenu-bg: #1B577A;">
        <li class="tab-controller__item" :class="[tabView == 0 ? 'active' : 'non', Check(AdminRoles) ? '--parent' : '']">
          <router-link class="item-link" :to="{ name: 'serials'}"> Serial numbers </router-link>
          <ul class="item-submenu">
            <!--
            <li class="item-submenu__item">
              <router-link class="submenu-link" :to="{ name: 'lists-overview'}"> Overview </router-link>
            </li>
            <li class="item-submenu__item">
              <router-link class="submenu-link" :to="{ name: 'lists-approve'}"> Approve </router-link>
            </li>
            -->
          </ul>
        </li>
      </ul>
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
  import { Permissions } from '@/helpers/Permissions';
  import { BPA } from '@/helpers/BPA';

  export default {
    name: 'Products',
    mixins: [Permissions, BPA],
    data() {
      return {
        showTabs: true,
        tabView: 0,
        RouteMapper: {'serials': 0},
        AdminRoles: [/*0, 3, 4, 25*/ 'admin', 'scan_admin', 'scan', 'sn_admin'],
        route: null
      }
    },
    created() {
      this.IsInRouteMapper();
    },
    mounted() {
      this.showTabs = this.tabs.length > 1;
    },
    watch: {
      $route () {
        this.IsInRouteMapper();
      }
    },
    computed: {
      tabs() {
        return document.querySelectorAll('.tab-controller__item');
      }
    },
    methods: {
      IsInRouteMapper() {
        this.route = this.$route.name;
        let name = this.route.split('-')[0];
        if (this.RouteMapper[name] !== null) {
          this.tabView = this.RouteMapper[name];
        }
      },
      Check(required) {
        return BPA.permissions(required).length;
      },
      IsSuperUser() {
        return this.Check([/*0*/ 'admin']);
      },
      IsMainCompany() {
        return BPA.util.IsMainCompany();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hidden {
    display: none;
  }
</style>